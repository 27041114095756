<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="易年审" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>

    <div style="text-align: center;" class="pdtop">
      <!-- 输入任意文本 -->
      <!-- <van-field v-model="text" label="基础价格" /> -->
      <div class="jiage">
        <span class="left jichu">基础价格</span>
        <span class="left" style="color:#07c160">￥{{fee}}元（全国发顺丰）</span>
      </div>
      <van-field v-model="contactPerson" label="收件人" placeholder="快递收件人姓名" />
      <van-field
        readonly
        clickable
        name="area"
        :value="value"
        v-if="isok != 'true'"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      >
        <template #button>
          <van-icon name="arrow-down" />
        </template>
      </van-field>
      <van-popup v-model="showArea" position="bottom">
        <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
      </van-popup>
      <van-field v-model="consigneeAddress" label="详细地址" placeholder="详细地址，务必准确填写" />

      <!-- 输入手机号，调起手机号键盘 -->
      <van-field v-model="phone" type="tel" label="手机号码" placeholder="收件人手机号码" />
      <van-field
        v-model="verCode"
        v-if="isok != 'true'"
        type="digit"
        center
        clearable
        label="验证码"
        placeholder="请点击发送并获取"
      >
        <template #button>
          <span style="color:#07c160">
            <span v-if="!sendMsgDisabled" @click="fasong" v-preventReClick>获取验证码</span>
            <span v-if="sendMsgDisabled">{{codeTime+'秒后获取'}}</span>
          </span>
        </template>
      </van-field>
      <!-- （含正、副页） -->
      <div class="xz" v-if="sbisok">行驶证</div>
      <div class="tupian" style=" text-align: center;">
        <!-- <img class="img_dw" v-if="!phisok" src="../../assets/img/xsz.png" alt /> -->
        <van-uploader :after-read="onRead" v-if="sbisok">
          <img class="shang" src="../../assets/img/xsz.png" alt />
        </van-uploader>
        <div class="xinxi" v-if="!sbisok">
          <div class="xinxi_A">
            <span class="xin_b">车牌号码：</span>
            <span class="xinxi_b">{{carform.carNo}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">车辆类型：</span>
            <span class="xinxi_b">{{carform.carModel}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">所有人：</span>
            <span class="xinxi_b">{{carform.owner}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">使用性质：</span>
            <span class="xinxi_b">{{carform.useNature}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">品牌型号：</span>
            <span class="xinxi_b">{{carform.carBrand}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">车架号：</span>
            <span class="xinxi_b">{{carform.vin}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">发动机号：</span>
            <span class="xinxi_b">{{carform.enginNo}}</span>
          </div>
          <div class="xinxi_A">
            <span class="xin_b">注册日期：</span>
            <span class="xinxi_b">{{carform.registerDate}}</span>
          </div>
        </div>
      </div>
      <van-button
        type="primary"
        color="#0d906e"
        v-if="isok != 'true'"
        class="anniu"
        @click="toyuyue"
      >立即申领</van-button>
      <div style="height: 60px;"></div>
    </div>
    <van-dialog
      v-model="showcar"
      title
      show-cancel-button
      class="cartan"
      @cancel="toquxiao"
      @confirm="toqueren"
    >
      <div class="xinxi" style="margin-bottom: 20px;">
        <div class="xinxi_A">
          <span class="xin_b">车牌号码：</span>
          <span class="xinxi_b">{{carform.carNo}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">车辆类型：</span>
          <span class="xinxi_b">{{carform.carModel}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">所有人：</span>
          <span class="xinxi_b">{{carform.owner}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">使用性质：</span>
          <span class="xinxi_b">{{carform.useNature}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">品牌型号：</span>
          <span class="xinxi_b">{{carform.carBrand}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">车架号：</span>
          <span class="xinxi_b">{{carform.vin}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">发动机号：</span>
          <span class="xinxi_b">{{carform.enginNo}}</span>
        </div>
        <div class="xinxi_A">
          <span class="xin_b">注册日期：</span>
          <span class="xinxi_b">{{carform.registerDate}}</span>
        </div>
      </div>
    </van-dialog>
    <tabbarcar :active="1" v-if="isok != 'true'"></tabbarcar>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import tabbarcar from "../../components/tabbarcar.vue";
import {
  orcdrive,
  timelist,
  carsave,
  mallmsgCode,
  cardetails
} from "../../api/check.js";
import preventReClick from "../../assets/preventReClick";
import { Area } from "vant";
import areaList from "../../components/area.js";
import Exif from "exif-js";
// import { areaList } from '@vant/area-data';
export default {
  components: {
    tabbarcar,
    areaList
  },
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      fileList: "",
      fee: 0.01,
      carform: {}, //识别得车辆信息
      sbisok: true,
      showcar: false,
      isok: this.$route.query.isok,
      id: this.$route.query.id,
      contactPerson: "", //联系人
      phone: "", //联系人手机
      verCode: "", //验证码
      consigneeAddress: "", //收件地址
      files: {
        name: "",
        type: ""
      },
      text: "",
      tel: "",
      list: {},
      actv: 1,
      loading: false,
      value: "",
      showArea: false,
      codeTime: 59, // 发送验证码倒计时
      sendMsgDisabled: false,
      areaList: areaList // 数据格式见 Area 组件文档
    };
  },
  methods: {
    onConfirm(values) {
      console.log(values);
      this.value = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("");
      this.showArea = false;
    },
    baocuo(e) {
      var err = "userID=" + this.userInfo.customerId + "/捕获的错误" + e;
      userModel
        .qderror({ error: err })
        .then(res => {
          console.log(res);
        })
        .catch();
    },
    //点击取消识别弹窗
    toquxiao() {
      console.log("点了取消");
      this.carform = {};
    },
    toqueren() {
      this.showcar = false;
      this.sbisok = false;
    },
    toyuyue() {
      var _this = this;
      if (this.contactPerson == "" || this.contactPerson == null) {
        this.$toast("请先输入收件人！");
        return;
      } else if (this.value == "" || this.value == null) {
        this.$toast("请先选择地区！");
        return;
      } else if (this.consigneeAddress == "" || this.consigneeAddress == null) {
        this.$toast("请先输入详细地址！");
        return;
      } else if (_this.phone == "" || !_this.phone) {
        _this.$toast("请先输入手机号码");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(_this.phone)) {
        _this.$toast("手机号码格式错误");
        return;
      } else if (this.verCode == "" || this.verCode == null) {
        this.$toast("请先输入验证码！");
        return;
      } else if (this.carform.driveUrl == "" || this.carform.driveUrl == null) {
        this.$toast("请先上传行驶证！");
        return;
      }
      var consigneeAddress = this.value + this.consigneeAddress;
      var data = {
        appointDate: "",
        carModel: this.carform.carModel,
        carNo: this.carform.carNo,
        carVin: this.carform.vin,
        cid: "",
        consigneeAddress: consigneeAddress,
        contactPerson: this.contactPerson,
        enginNo: this.carform.enginNo,
        fee: this.fee,
        phone: this.phone,
        picRul: this.carform.driveUrl,
        remark: this.remark,
        type: 1,
        uid: this.userInfo.id,
        useNature: this.carform.useNature,
        verCode: this.verCode,
        companyName: this.companyName
      };
      carsave(data).then(res => {
        if (res.code == 200) {
          _this.$toast("提交成功");
          setTimeout(() => {
            _this.$router.push({
              path: "my_order_details",
              query: {
                id: res.data.id
              }
            });
          }, 900);
        }
        console.log(res);
      });
    },
    // 组件方法 获取 流
    async onRead(file) {
      console.log(file);

      var _this = this;
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.picValue = file.file; // 文件流
      this.imgPreview(this.picValue);
    },
    // 处理图片
    imgPreview(file) {
      let self = this;
      try {
        // 你的逻辑代码
        let Orientation;
        //去获取拍照时的信息，解决拍出来的照片旋转问题
        Exif.getData(file, function() {
          Orientation = Exif.getTag(this, "Orientation");
        });
        // 看支持不支持FileReader
        if (!file || !window.FileReader) return;
        if (/^image/.test(file.type)) {
          // 创建一个reader
          let reader = new FileReader();
          // 将图片2将转成 base64 格式
          reader.readAsDataURL(file);
          // 读取成功后的回调
          reader.onloadend = function() {
            // console.log(this.result);
            let result = this.result;
            let img = new Image();
            img.src = result;
            //判断图片是否大于500K,是就直接上传，反之压缩图片
            if (this.result.length <= 500 * 1024) {
              self.headerImage = this.result;
              self.postImg();
            } else {
              img.onload = function() {
                let data = self.compress(img, Orientation);
                self.headerImage = data;
                self.postImg();
              };
            }
          };
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        self.baocuo(e);
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      try {
        // 你的逻辑代码
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        //瓦片canvas
        let tCanvas = document.createElement("canvas");
        let tctx = tCanvas.getContext("2d");
        // let initSize = img.src.length;
        let width = img.width;
        let height = img.height;
        //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
        let ratio;
        if ((ratio = (width * height) / 2000000) > 1) {
          // console.log("大于400万像素");
          ratio = Math.sqrt(ratio);
          width /= ratio;
          height /= ratio;
        } else {
          ratio = 1;
        }
        canvas.width = width;
        canvas.height = height;
        //    铺底色
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        //如果图片像素大于100万则使用瓦片绘制
        let count;
        if ((count = (width * height) / 1000000) > 1) {
          // console.log("超过100W像素");
          count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
          //      计算每块瓦片的宽和高
          let nw = ~~(width / count);
          let nh = ~~(height / count);
          tCanvas.width = nw;
          tCanvas.height = nh;
          for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
              tctx.drawImage(
                img,
                i * nw * ratio,
                j * nh * ratio,
                nw * ratio,
                nh * ratio,
                0,
                0,
                nw,
                nh
              );
              ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
            }
          }
        } else {
          ctx.drawImage(img, 0, 0, width, height);
        }
        //修复ios上传图片的时候 被旋转的问题
        if (Orientation != "" && Orientation != 1) {
          switch (Orientation) {
            case 6: //需要顺时针（向左）90度旋转
              this.rotateImg(img, "left", canvas);
              break;
            case 8: //需要逆时针（向右）90度旋转
              this.rotateImg(img, "right", canvas);
              break;
            case 3: //需要180度旋转
              this.rotateImg(img, "right", canvas); //转两次
              this.rotateImg(img, "right", canvas);
              break;
          }
        }
        //进行最小压缩
        let ndata = canvas.toDataURL("image/jpeg", 0.1);
        tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
        return ndata;
      } catch (e) {
        // 错误处理代码片段

        console.log(e);
        this.baocuo(e);
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      try {
        // 你的逻辑代码
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        //瓦片canvas
        let tCanvas = document.createElement("canvas");
        let tctx = tCanvas.getContext("2d");
        // let initSize = img.src.length;
        let width = img.width;
        let height = img.height;
        //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
        let ratio;
        if ((ratio = (width * height) / 2000000) > 1) {
          // console.log("大于400万像素");
          ratio = Math.sqrt(ratio);
          width /= ratio;
          height /= ratio;
        } else {
          ratio = 1;
        }
        canvas.width = width;
        canvas.height = height;
        //    铺底色
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        //如果图片像素大于100万则使用瓦片绘制
        let count;
        if ((count = (width * height) / 1000000) > 1) {
          // console.log("超过100W像素");
          count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
          //      计算每块瓦片的宽和高
          let nw = ~~(width / count);
          let nh = ~~(height / count);
          tCanvas.width = nw;
          tCanvas.height = nh;
          for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
              tctx.drawImage(
                img,
                i * nw * ratio,
                j * nh * ratio,
                nw * ratio,
                nh * ratio,
                0,
                0,
                nw,
                nh
              );
              ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
            }
          }
        } else {
          ctx.drawImage(img, 0, 0, width, height);
        }
        //修复ios上传图片的时候 被旋转的问题
        if (Orientation != "" && Orientation != 1) {
          switch (Orientation) {
            case 6: //需要顺时针（向左）90度旋转
              this.rotateImg(img, "left", canvas);
              break;
            case 8: //需要逆时针（向右）90度旋转
              this.rotateImg(img, "right", canvas);
              break;
            case 3: //需要180度旋转
              this.rotateImg(img, "right", canvas); //转两次
              this.rotateImg(img, "right", canvas);
              break;
          }
        }
        //进行最小压缩
        let ndata = canvas.toDataURL("image/jpeg", 0.1);
        tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
        return ndata;
      } catch (e) {
        // 错误处理代码片段

        console.log(e);
        this.baocuo(e);
      }
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      try {
        // 你的逻辑代码
        //最小与最大旋转方向，图片旋转4次后回到原方向
        const min_step = 0;
        const max_step = 3;
        if (img == null) return;
        //img的高度和宽度不能在img元素隐藏后获取，否则会出错
        let height = img.height;
        let width = img.width;
        let step = 2;
        if (step == null) {
          step = min_step;
        }
        if (direction == "right") {
          step++;
          //旋转到原位置，即超过最大值
          step > max_step && (step = min_step);
        } else {
          step--;
          step < min_step && (step = max_step);
        }
        //旋转角度以弧度值为参数
        let degree = (step * 90 * Math.PI) / 180;
        let ctx = canvas.getContext("2d");
        switch (step) {
          case 0:
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0);
            break;
          case 1:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, 0, -height);
            break;
          case 2:
            canvas.width = width;
            canvas.height = height;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, -height);
            break;
          case 3:
            canvas.width = height;
            canvas.height = width;
            ctx.rotate(degree);
            ctx.drawImage(img, -width, 0);
            break;
        }
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    //这里写接口
    async postImg() {
      var _this = this;
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      try {
        // 你的逻辑代码
        let file = this.dataURLtoFile(this.headerImage);
        let formData = new window.FormData();
        formData.append("file", file);
        console.log(formData);
        orcdrive(formData)
          .then(e => {
            console.log(e);
            if (e.code == 200) {
              loading.clear();
              this.carform = e.data;
              this.showcar = true;
            }
            // else if (e.data.modify == 1) {
            //   _this.shenhe = true;
            // } else if (e.data.modify == 2) {
            //   _this.addForm.driveUrl = e.data.driveUrl;
            //   _this.$toast("您提交的行驶证照片有误，请重新上传！");
            // }
          })
          .catch();
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      try {
        // 你的逻辑代码
        var arr = dataurl.split(","),
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], this.files.name, {
          type: this.files.type
        });
      } catch (e) {
        // 错误处理代码片段
        console.log(e);
        this.baocuo(e);
      }
    },

    //发送验证码
    fasong() {
      if (this.phone == null) {
        this.$toast("请输入手机号码");
        return;
      }
      if (this.phone == "") {
        this.$toast("请输入手机号码");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      const data = {
        phone: this.phone
      };
      var _this = this;
      mallmsgCode(data)
        .then(e => {
          if (e.code == 200) {
            _this.$toast("验证码已发送到您手机请查收");
            _this.sendMsgDisabled = true;
            let codeInterval = window.setInterval(function() {
              if (_this.codeTime-- <= 0) {
                _this.codeTime = 59;
                _this.sendMsgDisabled = false;
                window.clearInterval(codeInterval);
              }
            }, 1000);
          }
        })
        .catch(() => loading.clear());
    },
    //详情
    namelist() {
      this.actv = 0;
      var data = {
        id: this.id
      };
      cardetails(data).then(e => {
        this.sbisok = false;
        this.consigneeAddress = e.data.consigneeAddress;
        this.fee = e.data.fee;
        this.appointDate = e.data.appointDate;
        this.contactPerson = e.data.contactPerson;
        this.phone = e.data.phone;
        this.checked = e.data.comeDoor == 1 ? true : false;
        this.comeAddress = e.data.comeAddress;
        this.carform = {
          carNo: e.data.carNo,
          carModel: e.data.carModel,
          useNature: e.data.useNature,
          owner: e.data.owner,
          carBrand: e.data.carBrand,
          vin: e.data.vin,
          enginNo: e.data.enginNo,
          registerDate: e.data.registerDate
        };
      });
    },
    back() {
      if (this.isok == "true") {
        WeixinJSBridge.call("closeWindow");
      } else {
        // this.$router.go(-1);
        this.$router.push({
          path: "inspect_car"
        });
      }
    }
  },
  created() {
    if (this.$route.query.isok == "true") {
      this.namelist();
    }
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.hset {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  overflow: hidden;
}
.hes_a {
  width: 50%;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  float: left;
  color: #666;
  position: relative;
}

.pdtop {
  padding-top: 48px;
}
.xian {
  background: rgb(90, 97, 192);
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1.5px;
  margin-bottom: 13px;
  width: 40%;
  margin-left: -20%;
}
.cors {
  color: rgb(90, 97, 192);
}
.mian {
  background: #f6fcff;
  margin: 0 15px;
  text-align: left;
  border-radius: 10px;
  font-size: 15px;
  padding: 15px 15px 25px 15px;
  color: #666;
}
.tesx {
  // padding-left: 15px;
  padding: 2px 0 2px 15px;
}
.dian {
  font-weight: bold;
  margin-right: 5px;
  color: #000;
  float: left;
}
.ban {
  // font-weight: bold;
  color: #000;
  font-size: 16px;
  margin-bottom: 2px;
}
.anniu {
  margin: auto;
  width: 90%;
  height: 40px;
  margin-top: 25px;
}
.jiage {
  box-sizing: border-box;
  line-height: 45px;
  text-align: left;

  color: #646566;
  font-size: 14px;
  margin: 0 15px;
  border-bottom: 1px solid #ebedf0;
  overflow: hidden;
}
.jichu {
  width: 100px;
}
.xz {
  text-align: left;
  font-size: 14px;
  line-height: 45px;
  margin: 0 15px;
  color: #646566;
}
.shang {
  // width: 266px;
  width: 85%;
  // height: 100px;
}
.xinxi {
  padding: 15px 25px 0 25px;
  text-align: left;
  font-size: 15px;
  color: #666;
}
.xinxi_A {
  overflow: hidden;
}
.xinxi_b {
  color: #000;
}
.xin_b {
  float: left;
  width: 80px;
  text-align: right;
}
</style>